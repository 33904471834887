const stock =
`bind "0" "slot10"
bind "1" "slot1"
bind "2" "slot2"
bind "3" "slot3"
bind "4" "slot4"
bind "5" "slot5"
bind "6" "slot6"
bind "7" "slot7"
bind "8" "slot8"
bind "9" "slot9"
bind "a" "+moveleft"
bind "b" "buymenu"
bind "d" "+moveright"
bind "e" "+use"
bind "f" "+lookatweapon"
bind "g" "drop"
bind "i" "show_loadout_toggle"
bind "k" "+voicerecord"
bind "m" "teammenu"
bind "q" "lastinv"
bind "r" "+reload"
bind "s" "+back"
bind "t" "+spray_menu"
bind "u" "messagemode2"
bind "w" "+forward"
bind "x" "slot12"
bind "y" "messagemode"
bind "z" "+radialradio2"
bind "\`" "toggleconsole"
bind "," "buyammo1"
bind "." "buyammo2"
bind "SPACE" "+jump"
bind "TAB" "+showscores"
bind "ESCAPE" "cancelselect"
bind "DEL" "mute"
bind "PAUSE" "pause"
bind "SHIFT" "+speed"
bind "CTRL" "+duck"
bind "F3" "autobuy"
bind "F4" "rebuy"
bind "F5" "jpeg"
bind "F6" "save quick"
bind "F7" "load quick"
bind "F10" "quit prompt"
bind "MOUSE1" "+attack"
bind "MOUSE2" "+attack2"
bind "MOUSE3" "player_ping"
bind "MWHEELUP" "invprev"
bind "MWHEELDOWN" "invnext"
`;

export default stock;